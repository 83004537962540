var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "8",
      "lg": "8",
      "xs": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "placeholder": "Search By Project Title / Company Name / Contact Person...",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Enter Estimation Range",
      "config": {
        mode: 'range',
        defaultDate: [this.filterStartDate, this.filterEndDate]
      }
    },
    on: {
      "input": _vm.loadItems
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1), _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.projectTypeConstants,
      "reduce": function reduce(item) {
        return item.name;
      },
      "label": "name",
      "placeholder": "By Project Type"
    },
    on: {
      "input": _vm.filterByProjectType
    },
    model: {
      value: _vm.filterProjectType,
      callback: function callback($$v) {
        _vm.filterProjectType = $$v;
      },
      expression: "filterProjectType"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.paymentTypeConstants,
      "reduce": function reduce(item) {
        return item.name;
      },
      "label": "name",
      "placeholder": "By Payment Type"
    },
    on: {
      "input": _vm.filterByPaymentType
    },
    model: {
      value: _vm.filterPaymentType,
      callback: function callback($$v) {
        _vm.filterPaymentType = $$v;
      },
      expression: "filterPaymentType"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "options": _vm.projectStatusConstants,
      "reduce": function reduce(item) {
        return item.name;
      },
      "label": "name",
      "placeholder": " By Project Status"
    },
    on: {
      "input": _vm.filterByStatusType
    }
  })], 1)], 1)], 1)])], 1), _c('div', [_c('b-card', [_c('div', [_c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "rows": _vm.rows,
      "columns": _vm.columns,
      "styleClass": "vgt-table table-custom-style striped",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row$lead, _props$row$lead$data, _props$row2, _props$row2$lead, _props$row2$lead$data, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7, _props$row8, _props$row9, _props$row10, _props$column2, _props$row11, _props$row11$lead, _props$row11$lead$dat, _props$row12, _props$row12$lead, _props$row12$lead$dat, _props$row13, _props$row13$lead, _props$row13$lead$dat, _props$row14, _props$row14$lead, _props$row14$lead$dat, _props$row15, _props$row15$lead, _props$row15$lead$dat, _props$row16, _props$row16$lead, _props$row16$lead$dat, _props$row17, _props$row17$lead, _props$row17$lead$dat, _props$row18, _props$row18$lead, _props$row18$lead$dat, _props$column3, _props$row19, _props$row20, _props$column4, _props$row21, _props$row22, _props$column5, _props$row23, _props$row24, _props$column6, _props$row25, _props$row26, _props$column7, _props$row27, _props$row28;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_project_info' ? [_c('span', [_vm._v(" Title: "), _vm.$permissionAbility(_vm.PROJECT_SHOW, _vm.permissions) ? [_c('b-link', {
          attrs: {
            "href": _vm.getPageUrl(props.row.id)
          },
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : (_props$row$lead = _props$row.lead) === null || _props$row$lead === void 0 ? void 0 : (_props$row$lead$data = _props$row$lead.data) === null || _props$row$lead$data === void 0 ? void 0 : _props$row$lead$data.title))])] : [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : (_props$row2$lead = _props$row2.lead) === null || _props$row2$lead === void 0 ? void 0 : (_props$row2$lead$data = _props$row2$lead.data) === null || _props$row2$lead$data === void 0 ? void 0 : _props$row2$lead$data.title) + " ")]], 2), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.project_type ? _c('div', [_c('span', [_vm._v(" Type: "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatType(props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.project_type)))])], 1)]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.project_type ? _c('div', [_c('span', [_vm._v(" Duration: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row6 = props.row) === null || _props$row6 === void 0 ? void 0 : _props$row6.time_duration) + " Months")])])]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.total_milestone ? _c('div', [_c('span', [_vm._v(" Total Milestone: "), _c('b', [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row8 = props.row) === null || _props$row8 === void 0 ? void 0 : _props$row8.milestone) + " + " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.extra_milestone) + " (Extra) = " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.total_milestone) + " ")])])]) : _vm._e()])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_client_info' ? [_c('div', {
          staticClass: "cell-with-description"
        }, [_c('span', [_vm._v(" Company Name: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : (_props$row11$lead = _props$row11.lead) === null || _props$row11$lead === void 0 ? void 0 : (_props$row11$lead$dat = _props$row11$lead.data) === null || _props$row11$lead$dat === void 0 ? void 0 : _props$row11$lead$dat.company_name))])])]), _c('div', {
          staticClass: "cell-with-description"
        }, [_c('span', [_vm._v(" Contact Person: "), _c('b', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : (_props$row12$lead = _props$row12.lead) === null || _props$row12$lead === void 0 ? void 0 : (_props$row12$lead$dat = _props$row12$lead.data) === null || _props$row12$lead$dat === void 0 ? void 0 : _props$row12$lead$dat.key_contact_person))])])]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row13 = props.row) !== null && _props$row13 !== void 0 && (_props$row13$lead = _props$row13.lead) !== null && _props$row13$lead !== void 0 && (_props$row13$lead$dat = _props$row13$lead.data) !== null && _props$row13$lead$dat !== void 0 && _props$row13$lead$dat.email ? _c('div', [_c('span', {
          attrs: {
            "className": "text-truncate text-muted mb-0"
          }
        }, [_vm._v(" Email: "), _c('b-link', {
          attrs: {
            "href": 'mailto:' + (props === null || props === void 0 ? void 0 : (_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : (_props$row14$lead = _props$row14.lead) === null || _props$row14$lead === void 0 ? void 0 : (_props$row14$lead$dat = _props$row14$lead.data) === null || _props$row14$lead$dat === void 0 ? void 0 : _props$row14$lead$dat.email)
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : (_props$row15$lead = _props$row15.lead) === null || _props$row15$lead === void 0 ? void 0 : (_props$row15$lead$dat = _props$row15$lead.data) === null || _props$row15$lead$dat === void 0 ? void 0 : _props$row15$lead$dat.email) + " ")])], 1)]) : _vm._e()]), _c('div', {
          staticClass: "cell-with-description"
        }, [props !== null && props !== void 0 && (_props$row16 = props.row) !== null && _props$row16 !== void 0 && (_props$row16$lead = _props$row16.lead) !== null && _props$row16$lead !== void 0 && (_props$row16$lead$dat = _props$row16$lead.data) !== null && _props$row16$lead$dat !== void 0 && _props$row16$lead$dat.mobile ? _c('div', [_vm._v(" Mobile: "), _c('span', {
          attrs: {
            "className": "text-truncate text-muted mb-0"
          }
        }, [_c('b-link', {
          attrs: {
            "href": 'tel:' + (props === null || props === void 0 ? void 0 : (_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : (_props$row17$lead = _props$row17.lead) === null || _props$row17$lead === void 0 ? void 0 : (_props$row17$lead$dat = _props$row17$lead.data) === null || _props$row17$lead$dat === void 0 ? void 0 : _props$row17$lead$dat.mobile)
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : (_props$row18$lead = _props$row18.lead) === null || _props$row18$lead === void 0 ? void 0 : (_props$row18$lead$dat = _props$row18$lead.data) === null || _props$row18$lead$dat === void 0 ? void 0 : _props$row18$lead$dat.mobile) + " ")])], 1)]) : _vm._e()])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_project_type' ? [props !== null && props !== void 0 && (_props$row19 = props.row) !== null && _props$row19 !== void 0 && _props$row19.project_type ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatType(props === null || props === void 0 ? void 0 : (_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : _props$row20.project_type)))])] : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_total_amount' ? [_c('FormatCurrency', {
          attrs: {
            "currency": props === null || props === void 0 ? void 0 : (_props$row21 = props.row) === null || _props$row21 === void 0 ? void 0 : _props$row21.currency,
            "amount": props === null || props === void 0 ? void 0 : (_props$row22 = props.row) === null || _props$row22 === void 0 ? void 0 : _props$row22.total_amount
          }
        })] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_currency' ? [_c('FormatCurrency', {
          attrs: {
            "type": 'currency',
            "currency": props === null || props === void 0 ? void 0 : (_props$row23 = props.row) === null || _props$row23 === void 0 ? void 0 : _props$row23.currency,
            "amount": props === null || props === void 0 ? void 0 : (_props$row24 = props.row) === null || _props$row24 === void 0 ? void 0 : _props$row24.total_amount
          }
        })] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column6 = props.column) === null || _props$column6 === void 0 ? void 0 : _props$column6.field) === 'format_payment_type' ? [props !== null && props !== void 0 && (_props$row25 = props.row) !== null && _props$row25 !== void 0 && _props$row25.payment_type ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(_vm._s(_vm.formatType(props === null || props === void 0 ? void 0 : (_props$row26 = props.row) === null || _props$row26 === void 0 ? void 0 : _props$row26.payment_type)))])] : _vm._e()] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column7 = props.column) === null || _props$column7 === void 0 ? void 0 : _props$column7.field) === 'format_status' ? [props !== null && props !== void 0 && (_props$row27 = props.row) !== null && _props$row27 !== void 0 && _props$row27.status ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-success"
          }
        }, [_vm._v("Approved")])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$row28 = props.row) === null || _props$row28 === void 0 ? void 0 : _props$row28.status) === false ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v("Pending")])] : _vm._e()] : _vm._e(), props.column.field === 'action' ? _c('span', [_c('span', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_vm.$permissionAbility(_vm.PROJECT_SHOW, _vm.permissions) ? [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "EyeIcon"
          }
        }), _c('span', [_vm._v("Show")])], 1)] : _vm._e()], 2)], 1)]) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['50', '150', '200']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }