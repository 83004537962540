<template>
  <div>
    <div>
      <b-card-actions title="Filters" action-collapse>
        <div>
          <b-row class="mb-1">
            <b-col md="8" lg="8" xs="12">
              <b-form-input
                placeholder="Search By Project Title / Company Name / Contact Person..."
                type="text"
                class="d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font"
                v-on:keyup="onSearch"
                v-model.lazy="searchTerm"
              />
            </b-col>
            <b-col md="4" lg="4" xs="12">
              <flat-pickr
                  v-model="filterRangeDate"
                  v-on:input="loadItems"
                  class="form-control"
                  placeholder="Enter Estimation Range"
                  :config="{ mode: 'range', defaultDate: [this.filterStartDate, this.filterEndDate]}"
              />
            </b-col>
          </b-row>
        </div>

        <div>
          <b-row>
            <b-col md="6" lg="4" xs="12">
              <v-select
                v-model="filterProjectType"
                :options="projectTypeConstants"
                :reduce="(item) => item.name"
                v-on:input="filterByProjectType"
                label="name"
                placeholder="By Project Type"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
            <b-col md="6" lg="4" xs="12">
              <v-select
                v-model="filterPaymentType"
                :options="paymentTypeConstants"
                :reduce="(item) => item.name"
                v-on:input="filterByPaymentType"
                label="name"
                placeholder="By Payment Type"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>

            <b-col md="6" lg="4" xs="12">
              <v-select
                :options="projectStatusConstants"
                :reduce="(item) => item.name"
                v-on:input="filterByStatusType"
                label="name"
                placeholder=" By Project Status"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
          </b-row>
        </div>
      </b-card-actions>
    </div>

    <div>
      <b-card>
        <div>
          <!-- table -->
          <vue-good-table
            mode="remote"
            :rows="rows"
            :columns="columns"
            styleClass="vgt-table table-custom-style striped"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: [{ field: 'created_at', type: 'desc' }],
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <template v-if="props?.column?.field === 'format_project_info'">
                <span>
                  Title:
                  <template
                    v-if="$permissionAbility(PROJECT_SHOW, permissions)"
                  >
                    <b-link :href="getPageUrl(props.row.id)" v-on:click="onShowPage(props.row.id)">
                      {{ props?.row?.lead?.data?.title }}</b-link
                    >
                  </template>
                  <template v-else>
                    {{ props?.row?.lead?.data?.title }}
                  </template>
                </span>
                <div class="cell-with-description">
                  <div v-if="props?.row?.project_type">
                    <span>
                      Type:
                      <b-badge pill variant="light-primary">{{
                        formatType(props?.row?.project_type)
                      }}</b-badge>
                    </span>
                  </div>
                </div>
                <div class="cell-with-description">
                  <div v-if="props?.row?.project_type">
                    <span>
                      Duration:
                      <b>{{ props?.row?.time_duration }} Months</b>
                    </span>
                  </div>
                </div>

                <div class="cell-with-description">
                  <div v-if="props?.row?.total_milestone">
                    <span>
                      Total Milestone:
                      <b> {{ props?.row?.milestone }} +
                      {{ props?.row?.extra_milestone }} (Extra) =
                      {{ props?.row?.total_milestone }} </b>
                    </span>
                  </div>
                </div>
              </template>
              <template v-if="props?.column?.field === 'format_client_info'">
                <div class="cell-with-description">
                  <span>
                    Company Name: <b>{{ props?.row?.lead?.data?.company_name }}</b
                    >
                  </span>
                </div>
                <div class="cell-with-description">
                  <span>
                    Contact Person: <b>{{ props?.row?.lead?.data?.key_contact_person }}</b
                    >
                  </span>
                </div>

                <div class="cell-with-description">
                  <div v-if="props?.row?.lead?.data?.email">
                    <span className="text-truncate text-muted mb-0">
                      Email:
                      <b-link :href="'mailto:' + props?.row?.lead?.data?.email">
                        {{ props?.row?.lead?.data?.email }}
                      </b-link>
                    </span>
                  </div>
                </div>
                <div class="cell-with-description">
                  <div v-if="props?.row?.lead?.data?.mobile">
                    Mobile: <span className="text-truncate text-muted mb-0">
                      <b-link :href="'tel:' + props?.row?.lead?.data?.mobile">
                        {{ props?.row?.lead?.data?.mobile }}
                      </b-link></span
                    >
                  </div>
                </div>
              </template>
              <template v-if="props?.column?.field === 'format_project_type'">
                <template v-if="props?.row?.project_type">
                  <b-badge pill variant="light-primary">{{
                    formatType(props?.row?.project_type)
                  }}</b-badge>
                </template>
              </template>

              <template v-if="props?.column?.field === 'format_total_amount'">
                <FormatCurrency :currency="props?.row?.currency" :amount="props?.row?.total_amount" />
              </template>

              <template v-if="props?.column?.field === 'format_currency'">
                <FormatCurrency :type="'currency'" :currency="props?.row?.currency" :amount="props?.row?.total_amount" />
              </template>

              <template v-if="props?.column?.field === 'format_payment_type'">
                <template v-if="props?.row?.payment_type">
                  <b-badge pill variant="light-primary">{{
                    formatType(props?.row?.payment_type)
                  }}</b-badge>
                </template>
              </template>

              <template v-if="props?.column?.field === 'format_status'">
                <template v-if="props?.row?.status">
                  <b-badge pill variant="light-success">Approved</b-badge>
                </template>
                <template v-if="props?.row?.status === false">
                  <b-badge pill variant="light-danger">Pending</b-badge>
                </template>
              </template>

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>

                    <template
                      v-if="$permissionAbility(PROJECT_SHOW, permissions)"
                    >
                      <b-dropdown-item v-on:click="onShowPage(props.row.id)">
                        <feather-icon icon="EyeIcon" class="mr-50" />
                        <span>Show</span>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['50', '150', '200']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BRow,
  BCol,
  BFormTags,
  BFormDatepicker,
} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { required, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

import { projectTypeConstants } from "@/helpers/constant/projectTypeConstant";
import { paymentTypeConstants } from "@/helpers/constant/paymentTypeConstant";
import { projectStatusConstants } from "@/helpers/constant/projectStatusConstant";

import {
  PROJECT_SHOW,
  PROJECT_APPROVE,
  PROJECT_EDIT,
} from "@/helpers/permissionsConstant";
import flatPickr from 'vue-flatpickr-component'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "ProjectsView",
  components: {
    FormatCurrency,
    BForm,
    BButton,
    BCard,
    BLink,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    quillEditor,
    BRow,
    BCol,
    BFormTags,
    BFormDatepicker,
    flatPickr,
    BCardActions
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      PROJECT_SHOW,
      PROJECT_APPROVE,
      PROJECT_EDIT,
      filterRangeDate: "",
      filterStartDate: "",
      filterEndDate: "",
      filterProjectType: "",
      filterPaymentType: "",
      filterStatusType: "",
      projectTypeConstants,
      paymentTypeConstants,
      projectStatusConstants,
      columns: [
        {
          label: "Estimation Submitted",
          field: "created_at",
          formatFn: this.formatFnTableCreatedDate,
          sortable: true,
        },
        {
          label: "Project Info",
          field: "format_project_info",
          sortable: false,
        },
        {
          label: "Client Info",
          field: "format_client_info",
          sortable: false,
        },

        {
          label: "Total Amount",
          field: "format_total_amount",
          sortable: false,
        },
        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Payment Type",
          field: "format_payment_type",
          sortable: false,
        },
        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },

        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      pageLength: 50,
      params: [],
      rows: [],
      searchTerm: "",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  methods: {
    handleDateRangeChange()
    {
      if (this.filterRangeDate && this.filterRangeDate !== '') {
        const dates = this.filterRangeDate.split(' to ');

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format');
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    async getProjects(params) {
      return await this.$api.get("api/projects?include=lead", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
          filterStartDate: params.filterStartDate,
          filterEndDate: params.filterEndDate,
          filterProjectType: params.filterProjectType,
          filterPaymentType: params.filterPaymentType,
          filterStatusType: params.filterStatusType,
        },
      });
    },
    filterByPaymentType(paymentType) {
      try {
        this.filterPaymentType = paymentType;

        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    filterByProjectType(projectType) {
      try {
        this.filterProjectType = projectType;

        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    filterByStatusType(status) {
      try {
        const selectedStatus = (this.projectStatusConstants || []).find(
          (item) => {
            if (item.name == status) {
              return item;
            }
          }
        );

        this.filterStatusType = selectedStatus ? selectedStatus?.value : "";

        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    filterProjectsByEstimationDate() {
      this.loadItems();
    },

    formatType(value) {
      if (value) {
        const res = value.charAt(0).toUpperCase() + value.slice(1);
        return res;
      }
      return "N/A";
    },
    formatStatus(value) {
      if (value) {
        return "Approved";
      }
      return "Pending";
    },
    formatFnTableCreatedDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },

    resetModal() {
      this.modelType = "";
      this.searchInputType = "defined";
      this.title = "";
      this.description = "";
      this.noticeRecieverTypeValue = "";
      this.recievers = "";
      this.customRecievers = "";
      this.recieverIdOption = [];
    },

    onShowPage(id) {
      this.$router.push({
        name: "admin-project-details",
        params: { id },
      });
    },
    getPageUrl(id) {
      return this.$router.resolve({ name: "admin-project-details", params: { id } }).href;
    },
    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });

      this.loadItems();
    },

    async loadItems() {
      try {
        this.handleDateRangeChange()
        const [projects] = await Promise.all([
          this.getProjects({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterStartDate: this.filterStartDate,
            filterEndDate: this.filterEndDate,
            filterProjectType: this.filterProjectType,
            filterPaymentType: this.filterPaymentType,
            filterStatusType: this.filterStatusType,
          }),
        ]);
        const data = projects?.data?.data;

        const meta = projects?.data?.meta;
        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error 2",
            icon: "BellIcon",
            variant: "danger",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    onSortChange(params) {
      this.params = (params || []).map((item) => {
        let field = item.field;
        return {
          field: field,
          type: item.type,
        };
      });

      this.updateParams({
        sort: this.params,
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.cell-with-description div {
  margin: 2px 0px;
}
</style>
